import styled from "@emotion/styled"
import { getTypo, ETypo, COLOR, SPACE, BREAKPOINT } from "../../design/index"

export const Title = styled.h2`
  ${getTypo(ETypo.BIG_BODY)}
  text-transform: uppercase;
  margin-top: ${SPACE.SPACE64};
`

export const Team = styled.div`
  margin-top: ${SPACE.SPACE48};
  display: flex;
  flex-wrap: wrap;
`

export const TeamMember = styled.div`
  --itemsWide: 5;
  margin-bottom: ${SPACE.SPACE64};
  display: flex;
  flex-direction: column;
  flex: 0 0 calc(100% / var(--itemsWide));
  width: calc(100% / var(--itemsWide));
  align-content: flex-start;

  @media (max-width: ${BREAKPOINT.LARGE}) {
    --itemsWide: 4;
  }

  @media (max-width: ${BREAKPOINT.MEDIUM}) {
    --itemsWide: 2;
  }
`

export const Name = styled.span`
  ${getTypo(ETypo.FORM_TITLE)}
  margin-top: ${SPACE.SPACE16};
`

export const Role = styled.span`
  ${getTypo(ETypo.META)}
  font-weight: 500;
  font-style: italic;
  color: ${COLOR.BLUE300};
`
