import { graphql } from "gatsby"
import styled from "@emotion/styled"
import { COLOR, getTypo, ETypo, SPACE, BREAKPOINT } from "../design"
import GlobalWrapper from "../templates/global"
import Header from "../components/header"
import HeadLine from "../components/headline"
import Container from "../components/container"
import Team from "../components/team"

const Border = styled.div`
  width: 25%;
  height: 5px;
  background-color: ${COLOR.BLUE100};
  margin-bottom: ${SPACE.SPACE64};
`

const Columns = styled.div`
  display: flex;
  justify-content: space-between;
  ${getTypo(ETypo.BODY)}

  @media (max-width: ${BREAKPOINT.LARGE}) {
    flex-direction: column;
  }
`

const Column = styled.div`
  width: 45%;

  @media (max-width: ${BREAKPOINT.LARGE}) {
    width: 100%;

    & + & {
      margin-top: ${SPACE.SPACE64};
    }
  }
`

// @TODO The source of this should be the CMS
const teamMembers = [
  {
    id: "sage",
    name: "Matt Solari",
    role: "Founder",
  },
  {
    id: "cleo",
    name: "Cleo",
    role: "Design",
  },
  {
    id: "veksen",
    name: "veksen",
    role: "Code",
  },
  {
    id: "cw",
    name: "CW",
    role: "Design",
  },
  {
    id: "cube",
    name: "Cube",
    role: "Content",
  },
  {
    id: "mattW",
    name: "Matt W",
    role: "Design",
  },
  {
    id: "ataguan",
    name: "Ataguan",
    role: "Design",
  },
  {
    id: "brionwolf",
    name: "Brionwolf",
    role: "Code",
  },
  {
    id: "s3bas",
    name: "S3BAS",
    role: "Code",
  },
  {
    id: "jamesCarter",
    name: "James Carter",
    role: "Content",
  },
]

export default () => {
  return (
    <GlobalWrapper>
      <Header />
      <Container>
        <HeadLine>
          UX House was started in 2019 and is a remote organisation built around mutual education, sustainable design,
          and emerging technology. We are human-centered thinkers who help each other improve their craft and create
          better experiences for all.
        </HeadLine>
        <Border />
        <Columns>
          <Column>
            The UX House community has come to exist in the wake of a rapidly evolving industry where it isn’t easy to
            find stable ground. The vast amount of tools, frameworks, and methodologies available to us all who work
            under the umbrella of UX can be difficult to traverse. Here at UX House we aim to provide a medium-agnostic
            setting where students, professionals, and enthusiasts alike can meet and collaborate with ease.
          </Column>
          <Column>
            As such, we focus on strengthening the foundation and principles of our work. Our mission is to continue to
            build the collaborative structure for which individuals at any level can come together and ensure diligence
            and quality in their work. We believe that by doing this, we will set better and more effective precedents
            for the industry while cultivating a symbiotic culture for a diverse community.
          </Column>
        </Columns>
        <Team teamMembers={teamMembers} />
      </Container>
    </GlobalWrapper>
  )
}
