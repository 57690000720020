import { Ataguan, Brionwolf, Cleo, Cube, CW, JamesCarter, MattW, S3bas, Sage, Veksen } from "../../assets/personas"
import * as S from "./team.style"

const persona = {
  sage: Sage,
  cleo: Cleo,
  veksen: Veksen,
  cw: CW,
  cube: Cube,
  mattW: MattW,
  ataguan: Ataguan,
  brionwolf: Brionwolf,
  s3bas: S3bas,
  jamesCarter: JamesCarter,
}

interface TeamMember {
  id: string
  name: string
  role: string
}

const TeamMember = ({ id, name, role }: TeamMember) => {
  const Persona = persona[id]
  return (
    <S.TeamMember>
      <Persona />
      <S.Name>{name}</S.Name>
      <S.Role>{role}</S.Role>
    </S.TeamMember>
  )
}

interface TeamProps {
  teamMembers: TeamMember[]
}

const Team = ({ teamMembers }: TeamProps) => (
  <div>
    <S.Title>The UXH Team</S.Title>
    <S.Team>
      {teamMembers.map((member) => (
        <TeamMember {...member} key={member.id} />
      ))}
    </S.Team>
  </div>
)

export default Team
